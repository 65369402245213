import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .loja {
    .css-1a7rm5r-control {
      border-bottom: 2px solid #db1212;
    }
  }

  .invalidInput {
    border-bottom: 2px solid #db1212;
  }

  .disabledInput {
    opacity: 0.5;
    cursor: no-drop;
  }

  .tbody,
  td,
  tr {
    padding: 18px !important;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;
