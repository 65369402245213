import * as yup from 'yup';

export const getSchema = (isUpdate: boolean): any => {
  return yup.object().shape({
    ...(isUpdate === false && {
      busca_parceiro: yup.object().shape({
        value: yup.number().required(),
        label: yup.string().required(),
      }),
      cod_cc: yup.object().shape({
        value: yup.number().required(),
        label: yup.string().required(),
      }),
      cod_categoria: yup.object().shape({
        value: yup.number().required(),
        label: yup.string().required(),
      }),
      cod_finalizadora: yup.object().shape({
        value: yup.number().required(),
        label: yup.string().required(),
      }),
    }),
    cod_cc: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .when([], {
        is: () => isUpdate,
        then: (schema) =>
          schema.test(
            'cod-cc-empty-check',
            'cod_cc é obrigatório quando está vazio durante a atualização',
            (value) => value?.label !== '',
          ),
      }),
    cod_categoria: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .when([], {
        is: () => isUpdate,
        then: (schema) =>
          schema.test(
            'cod-categoria-empty-check',
            'cod_categoria é obrigatório quando está vazio durante a atualização',
            (value) => value?.label !== '',
          ),
      }),
    cod_finalizadora: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .when([], {
        is: () => isUpdate,
        then: (schema) =>
          schema.test(
            'cod_finalizadora-empty-check',
            'cod_finalizadora é obrigatória quando está vazio durante a atualização',
            (value) => value?.label !== '',
          ),
      }),
    cod_loja: yup.object().shape({
      value: yup.number().required(),
      label: yup.string().required(),
    }),
    dta_emissao: yup.string().required(),
    dta_entrada: yup.string().required(),
    val_parcela: yup.string().required(),
    num_docto: yup.string().required(),
    dta_vencimento: yup.string().required(),
    dta_quitada: yup.string().when('flg_quitado', {
      is: true,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    flg_quitado: yup.boolean().notRequired(),
    des_observacao: yup.string().notRequired(),
    flg_dta_quitada: yup.boolean().notRequired(),
  });
};
